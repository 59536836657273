import type { AriaToastRegionProps } from '@react-aria/toast';
import { useToastRegion, useToast, AriaToastProps } from '@react-aria/toast';
import type { ToastState } from '@react-stately/toast';
import { ToastQueue, useToastQueue } from '@react-stately/toast';
import { cva } from 'class-variance-authority';
import React from 'react';
import Icon from '../icon/icon';
import { Pressable } from '../pressable/pressable';
import { Text } from '../text/text';

export interface ToastContent {
  title: string | React.ReactNode;
  variant?: 'success' | 'error';
  action?: React.ReactNode;
}

interface ToastRegionProps extends AriaToastRegionProps {
  state: ToastState<ToastContent>;
}

interface ToastProps extends AriaToastProps<ToastContent> {
  state: ToastState<ToastContent>;
}

const toastQueue = new ToastQueue<ToastContent>({
  maxVisibleToasts: 5,
});

function ToastRegion({ state, ...props }: ToastRegionProps) {
  const ref = React.useRef(null);
  const { regionProps } = useToastRegion(props, state, ref);

  return (
    <div
      {...regionProps}
      className="fixed bottom-6 right-6 z-[9999] flex flex-col gap-2 focus:outline-none"
      ref={ref}>
      {state.visibleToasts.map((toast) => (
        <Toast key={toast.key} state={state} toast={toast} />
      ))}
    </div>
  );
}

const toast = cva(
  'shadow-8 flex items-center justify-between gap-x-4 rounded-full py-1 pl-4 pr-1 focus:outline-none',
  {
    variants: {
      variant: {
        success: ['bg-positive-400', 'text-white'],
        error: ['bg-negative-400', 'text-white'],
      },
    },
    defaultVariants: {
      variant: 'success',
    },
  },
);

function Toast({ state, ...props }: ToastProps) {
  const ref = React.useRef(null);
  const { toastProps, titleProps, closeButtonProps } = useToast(props, state, ref);

  return (
    <div {...toastProps} className={toast({ variant: props.toast.content.variant })} ref={ref}>
      <div className="flex gap-x-3">
        <Text {...titleProps}>{props.toast.content.title}</Text>
        {props.toast.content.action}
      </div>
      <Pressable
        className="flex items-center rounded-full p-1.5 hover:bg-white/20 focus:outline-none focus:ring-1 focus:ring-white"
        {...closeButtonProps}>
        <Icon className="h-5 w-5" name="close" />
      </Pressable>
    </div>
  );
}

export { useToastQueue, ToastRegion, toastQueue };
